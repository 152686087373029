import React from "react";
// import { Container } from "react-bootstrap"
// import {PDFObject} from "react-pdfobject"
export default function TermsAndCondition() {
 
  return (<div className="terms">
    {/* <h2>{"Terms and Conditions"}</h2> */}
    <div>
      {`BY CLICKING "Accept & Continue" OR BY ACCESSING AND USING THIS INTERNET SERVICE YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT AS SET OUT BELOW. IF YOU DO NOT AGREE, PLEASE DO NOT USE THIS SERVICE.`}
    </div>
    <h3>{"LIMITED LIABILITY"}</h3>
    <div>
      {`Flo Harris Lodge and Flo Harris Lodge’s service providers will use reasonable endeavours to maintain an uninterrupted service. The stabilityand speed of the service is subject to the performance of the providers’ network, platform and gateway. Flo Harris Lodge does not guarantee or represent that your service will be uninterrupted or error-free. You acknowledge that the service,at times, may become unavailable for unexpected reasons. Flo Harris Lodge will use reasonable endeavours to address any issuesthat arise in a timely manner to minimise any negative impact to you and to other residents. Flo Harris Lodge will hold all itsservice providers to minimum service level agreements. You are liable for any bugs, viruses or other malicioussoftware that may infiltrate your device or the network as a result of your online activities. Flo Harris Lodge may report any illegalactivity detected on the network to the relevant authorities. You agree that your use of this service will be at yourown risk. Flo Harris Lodge and its service providers do not accept liability for:`}
    </div>
    <ul>
      <li>{`Loss or corruption of data in any circumstance; or`}</li>
      <li>{`Any defamatory, offensive or illegal intent of third parties, websites or films viewed on the service.`}</li>
    </ul>
    <h3>{`ACCEPTABLE USE`}</h3>
    <div>{`You agree to use the service in accordance with all relevant legislation and maintain the security of the property’s ITnetwork and facilities. The activities listed below are examples of unacceptable use of the network and may result inthe cancellation of your Internet account. You must not use this service:`}</div>
    <ul>
      <li>{`to breach any Commonwealth or State law;`}</li>
      <li>{`to breach the Flo Harris Lodge's House Rules (e.g. Cyber-Bullying);`}</li>
      <li>{`by sharing your personal internet account with others;`}</li>
      <li>{`to tamper with the system or any of the data ports or wireless access points throughout the property; and/or`}</li>
      <li>{`to receive, access, download, display, transmit and/or make available any illegal materials via any media.`}</li>
    </ul>
    <h3>{"CHANGES"}</h3>
    <div>{`Flo Harris Lodge reserves the right to change the above terms and conditions from time to time. Accessing the service by you willbe constituted as acceptance of any adjustments to the terms and conditions.`}</div>
    <h3>{"PRIVACY POLICY"}</h3>
    <div>{`Flo Harris Lodge's Privacy Policy complies with the Australian Privacy Act 1988 amended to conform to the Privacy Amendment(Enhancing Privacy Protection) Act 2012 (the “Privacy Act”). The Australian Privacy Principles (APP), contained in thePrivacy Act, impose privacy obligations on organisations to comply with the collection, use, storage and disclosure ofpersonal information. For the full policy please go to Flo Harris Lodge's website https://www.floharrislodge.org.au/.`}</div>
    <h4>{`For Assistance See Reception`}</h4>
  </div>);

}